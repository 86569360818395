import React from "react";

const Footer = () => {
    return (
        <footer className="footer footer-center p-4 text-base-content">
  <aside>
    <p>Copyright © 2024 - All right reserved by Soyeon Kim</p>
  </aside>
</footer>
    )
}

export default Footer;